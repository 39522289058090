<ng-container [ngSwitch]="step">
  <ng-container *ngSwitchCase="'code'">
    <form [formGroup]="emailVerificationForm" [hidden]="!!modifyEmailMode">
      <kz-modal-content align="center">
        <h1 class="kz-light-title"><kz-icons name="mail"></kz-icons>
          <ng-container *ngIf="mode === 'email'" i18n="@@emailVerification.title">An email has been sent to you</ng-container>
          <ng-container *ngIf="mode === 'phone'" i18n="@@phoneVerification.title">An SMS has been sent to you</ng-container>
        </h1>

        <p class="alert" *ngIf="isInKazaplan$ | async" i18n="@@emailVerification.inKazaplan">Your 3D plan will be saved at the end of the registration</p>
        <p class="text" *ngIf="mode === 'email'" i18n="@@emailVerification.text">To continue your experience on Kozikaza, <br>
          please enter the 6-digit code we sent to the following email address <span class="verification-email">{{(userStoreService.userStore | async)?.email}}</span></p>
        <p class="text" *ngIf="mode === 'phone'" i18n="@@phoneVerification.text">To continue your experience on Kozikaza, <br>
          please enter the 6-digit code we sent to the following phone number <span class="verification-email">{{phoneForm.get('phoneNumber').value }}</span></p>
        <div class="modify-email" *ngIf="mode === 'email'"><a i18n="@@emailVerification.emailModificationLink" (click)="modifyEmailMode = true; initEmailVerificationFormStatus()">Edit my email</a></div>
        <kz-field [hasError]="!!emailVerificationForm.get('code').errors && emailVerificationForm.get('code').touched" [isDisabled]="submitted" absErrorMsg="true">
          <label kzLabel for="code" *ngIf="mode === 'email'" i18n="@@emailVerification.label">The 6-digit code sent by email</label>
          <label kzLabel for="code" *ngIf="mode === 'phone'" i18n="@@phoneVerification.label">The 6-digit code sent by SMS</label>
          <input kzTextBox id="code" type="number"
                 oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                 maxlength="6"
                 class="input-code"
                 formControlName="code"
                 [required]="true" placeholder="000000"/>
          <p *ngIf="emailVerificationForm.get('code').touched && emailVerificationForm.get('code').errors?.required && !emailVerificationForm.get('code').pristine" kzError i18n="@@emailVerification.errors.required">Required</p>
          <p *ngIf="emailVerificationForm.get('code').touched && emailVerificationForm.get('code').errors?.pattern && !emailVerificationForm.get('code').pristine" kzError i18n="@@emailVerification.errors.pattern">Code must be 6 digits</p>
          <p *ngIf="emailVerificationForm.get('code').touched && emailVerificationForm.get('code').errors?.apiError && !emailVerificationForm.get('code').pristine" kzError >{{emailVerificationForm.get('code').errors?.apiError}}</p>

        </kz-field>

        <ng-container  *ngIf="(timer$ | async) as t; else resend;">
          <p class="countdown--label"><ng-container i18n="@@emailVerification.errors.code">You can ask for a new code in </ng-container>
            {{t | secondsToMinutes}} <ng-container i18n="@@emailVerification.errors.code.seconds">seconds</ng-container></p>
        </ng-container>
        <ng-template #resend>
          <a i18n="@@emailVerification.resendCode" class="resend-email" (click)="resendCode()">Resend code</a>
          <span></span>
        </ng-template>

      </kz-modal-content>
      <kz-modal-footer align="center">
        <button kzButton type="button"
                [disabled]="submitted || !emailVerificationForm.dirty || emailVerificationForm.invalid"
                [buttonState]="submitted ? 'loading' : 'default'"
                buttonTxtStyle="lowercase"
                (click)="submitAction()" data-cy="email-verification-submit"><span
                i18n="@@emailVerification.submitButton">Validate my registration</span></button>
      </kz-modal-footer>
    </form>

    <form [formGroup]="modifyEmailForm" [hidden]="!modifyEmailMode">
      <kz-modal-content align="center">
        <button (click)="modifyEmailMode = false; initModifyEmailFormStatus()" class="modify-email--return"><kz-icons name="arrowleft"></kz-icons><ng-container i18n="@@emailVerification.modifyEmail.return">Return</ng-container></button>
        <h1 class="kz-light-title"><kz-icons name="mail"></kz-icons>
          <ng-container i18n="@@emailVerification.modifyEmail.title">Edit my email address</ng-container></h1>

        <p class="text mb-20"><ng-container i18n="@@emailVerification.modifyEmail.text">Email address filled in</ng-container><br> <span
                class="verification-email">{{(userStoreService.userStore | async)?.email}}</span></p>
        <kz-field class="email-field" [hasError]="modifyEmailForm.get('email').errors?.duplicated || !!modifyEmailForm.get('email').errors && modifyEmailForm.get('email').touched" [isDisabled]="submitted" absErrorMsg="true">
          <label kzLabel for="email" i18n="@@emailVerification.modifyEmail.label">Your new email address</label>
          <input kzTextBox id="email" type="email"
                 formControlName="email"
                 [textboxState]="modifyEmailForm.get('email').pending ? 'loading' : (modifyEmailForm.get('email').valid ? 'valid' : 'default')"
                 [required]="true"
                 i18n-placeholder="@@emailVerification.modifyEmail.placeholder"
                 placeholder="Your new email address"/>
          <p *ngIf="modifyEmailForm.get('email').touched && !modifyEmailForm.get('email').pristine && modifyEmailForm.get('email').errors?.required"  kzError i18n="@@auth.errors.required">Required</p>
          <p *ngIf="!modifyEmailForm.get('email').pristine && modifyEmailForm.get('email').errors?.duplicated" class="email-field--duplicated" kzError>
            <span i18n="@@auth.errors.email.alreadyTaken">Email is already taken</span>
            <span (click)="linkToLogin(modifyEmailForm.get('email').value)" i18n="@@auth.errors.email.alreadyTaken.linkToLogin"  class="email-field--duplicated--link">It's me! Log in</span>
          </p>
          <p *ngIf="modifyEmailForm.get('email').touched && !modifyEmailForm.get('email').pristine && modifyEmailForm.get('email').errors?.pattern"  kzError i18n="@@auth.errors.email.valid">Email must be valid</p>
          <p *ngIf="modifyEmailForm.get('email').touched && !modifyEmailForm.get('email').pristine && modifyEmailForm.get('email').errors?.maxlength"  kzError i18n="@@auth.errors.email.maxlength">Email max length 200 Characters</p>
          <p *ngIf="modifyEmailForm.get('email').touched && !modifyEmailForm.get('email').pristine && modifyEmailForm.get('email').errors?.apiError"  kzError>{{ modifyEmailForm.get('email').errors?.apiError }}</p>

        </kz-field>

      </kz-modal-content>
      <kz-modal-footer align="center">
        <button kzButton type="button"
                [disabled]="submitted || !modifyEmailForm.dirty || modifyEmailForm.invalid"
                [buttonState]="submitted ? 'loading' : 'default'"
                buttonTxtStyle="lowercase"
                (click)="submitModifyAction()" data-cy="email-verification-submit"><span
                i18n="@@emailVerification.modifyEmail.submitButton">Edit email address</span></button>
      </kz-modal-footer>
    </form>
  </ng-container>
 
</ng-container>
