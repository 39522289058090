<div class="ie-banner" *ngIf="detectIE$ | async">
  <h1><ng-container i18n="@@ie-banner.title">The Kozikaza site is not compatible with Internet Explorer.</ng-container></h1>
  <p><ng-container i18n="@@ie-banner.text">We recommend that you use a compatible browser:</ng-container><a href="https://www.google.com/intl/fr_ALL/chrome/" target="_blank">Chrome</a>,
    <a href="https://www.mozilla.org/fr/firefox/new/" target="_blank">Firefox</a> <ng-container i18n="@@ie-banner.text2">or</ng-container> <a href="https://www.microsoft.com/fr-fr/windows/microsoft-edge" target="_blank">Edge</a>.</p>
</div>
<app-header *ngIf="!(routesService.routeDatas | async)?.noHeader"></app-header>
<router-outlet></router-outlet>
<router-outlet name="modal"></router-outlet>
<router-outlet name="auth"></router-outlet>
<router-outlet name="email-verification"></router-outlet>
<ng-template #debugVcr></ng-template>
<app-footer *ngIf="!(routesService.routeDatas | async)?.noFooter"></app-footer>
