import { NgModule } from '@angular/core';
import { NavigationComponent } from './navigation';
import { SearchComponent } from './search';
import { HeaderComponent } from './header.component';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { UtilsModule } from '../../../utils/utils.module';
import { OrderByDatePipe } from '../../../utils/pipes/order-by-date.pipe';
import { VerticalMenuComponent } from './responsive/vertical-menu';
import { ImgResizeModule } from '../../../shared/components/img-resize/img-resize.module';
import { KzClickOutsideModule } from '../../../kz-ui/ui/click-outside/kz-click-outside.module';
import { KzButtonModule } from '../../../kz-ui/ui/form/button/kz-button.module';
import { KzIconsModule } from '../../../kz-ui/ui/icon/kz-icons.module';
import { AccessControlDirective } from '../../../utils/directives/access-control.directive';
import { GoodDealsMenuComponent } from "../../good-deals/good-deals-menu/good-deals-menu.component";
import { AffiliateApiModule } from '@adeo/ngx-kozikaza-api/affiliate';
import { KzLocalizePipe } from "../../../utils/pipes/i18n/kz-localize.pipe";
import { CountdownTimerComponent } from "../../../shared/components/countdown-timer/countdown-timer.component";

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    UtilsModule,
    LayoutModule,
    ImgResizeModule,
    KzClickOutsideModule,
    KzButtonModule,
    KzIconsModule,
    AccessControlDirective,
    GoodDealsMenuComponent,
    AffiliateApiModule,
    KzLocalizePipe,
    CountdownTimerComponent
],
  declarations: [
    NavigationComponent,
    VerticalMenuComponent,
    SearchComponent,
    HeaderComponent,
  ],
  providers: [
    OrderByDatePipe,
  ],
  exports: [
    HeaderComponent,
  ]
})
export class HeaderModule { }
