<header (kzClickOutside)="hideMenus()"
        class="kz-header"
        #header
        [ngClass]="{
          'kz-header': true,
          'kz-header--simple': !isFullHeader,
          'kz-header--hidden': hideHeader,
          'kz-header--hovered': false,
          'kz-header--cant-display': false,
          'kz-header--no-transition': false,
          'kz-header--search-open': searchOpened
        }">
  <div class="kz-header-bar" *ngIf="!hideHeader">
    <div class="kz-header-navbar">
      <div class="kz-header-navbar-item--burger" *ngIf="isFullHeader">
        <button type="button" class="kz-header-burger-button" (click)="toggleRespMenuLayerAction(true)"><kz-icons name="burger"></kz-icons>Menu</button>
      </div>
      <div class="kz-header-navbar-item--logo">
        <a [routerLink]="['/'] | kzLocalize" [queryParams]="{noredirect: true}" class="kz-header-logo">
          <kz-icons name="kozikaza"></kz-icons>
        </a>
      </div>
      <div class="kz-header-navbar-item--nav"  *ngIf="isFullHeader">
        <app-header-nav (mouseover)="searchOpened=false"></app-header-nav>
      </div>
      <ng-container *appNgIfIsCommunity >
        <div class="kz-header-navbar-item--search-button">
          <button type="button" class="kz-header-search-toggler" aria-label="Rechercher" (click)="searchOpened=!searchOpened"><kz-icons #searchCTA name="search"></kz-icons></button>
        </div>
        <div class="kz-header-navbar-item--search" *ngIf="isFullHeader && !isMobile">
          <ng-container *ngIf="exception">
            <app-search (closed)="searchOpened = false" [opened]="searchOpened" (kzClickOutside)="searchOpened=false"
                        [exceptions]="[exception]"></app-search>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #connectedNavVcr></ng-template>
      <div class="kz-header-navbar-item--login" *ngIf="((isConnected | async) == false) && !hideLoginForm">
        <div class="kz-header-nav">
          <a kzButton [routerLink]="['', {outlets: {auth: ['authentification', 'login']}}]" class="kz-header-login" data-cy="btn-login">
            <span i18n="@@navbar.sign_in">Sign in</span>
          </a>
        </div>
      </div>
    </div>
  </div>
<app-countdown-timer></app-countdown-timer>
  <app-vertical-menu
          class="kz-header-menu kz-header-menu--nav"
          *ngIf="(isUniversalRendered && userAgentIsMobile) || (!isUniversalRendered && isMobile)"
          [@layerSlideFromLeftAnimation]="(openRespMenuLayer && isMobile)? 'open' : 'closed'"
          (closeLayer)="toggleRespMenuLayerAction(false)"
  ></app-vertical-menu>

</header>
<ng-template *ngIf="!(routesService.routeDatas | async)?.hideMobileStoresBanner" #fixedBannerMobileStores></ng-template>
