<div class="maintenance">
 
    <div class="alert_container">
        <div>
         <strong i18n="@@countdown-banner.text1"> The Kozikaza website will permanently close its doors on April 22, 2025.</strong>
        &nbsp;&nbsp; 
        <u><a href="https://support.kazaplan.com/hc/fr/categories/18935068232732-Fermeture-du-site-Kozikaza" target="_blank" i18n="@@countdown-banner.text2">Learn more</a></u>
        </div>
        <div class="countdown">
            <span class="flip-box" >{{ timeLeft.days }}</span> :
            <span class="flip-box" >{{ timeLeft.hours }}</span> :
            <span class="flip-box" >{{ timeLeft.minutes }}</span> :
            <span class="flip-box" >{{ timeLeft.seconds }}</span>
        </div>
    </div>

</div>